
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { AxiosResponse } from 'axios';
  import { isEmpty } from 'lodash';

  @Component
  export default class ImageUpload extends Vue {
    @Prop({ default: 'image-upload' }) name: string;
    @Prop({ default: 742 }) width: number;
    @Prop({ default: 695 }) height: number;

    public croppa: any = {
      imageSet: false
    };
    public error: string = '';

    upload() {
      if (!this.hasImage()) {
        return;
      }

      this.croppa.generateBlob((blob: Blob) => {
        const file = this.croppa.getChosenFile();
        const filename = isEmpty(file) ? 'file.jpg' : file.name;
        const request = {
          method: 'post',
          url: '/media',
          data: new FormData(),
          headers: {
            'content-type': 'multipart/form-data'
          }
        };

        request.data.append('file', new File([blob], filename));

        this.axios(request)
          .then((response: AxiosResponse) => {
            this.$emit('uploaded', response.data);

            this.$modal.hide(this.name);
          })
          .catch((error) => this.error = error.response.data.file[0]);
      });
    }

    hasImage() {
      return this.croppa.imageSet;
    }
  }
