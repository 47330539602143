
  import { Component, Prop, Vue } from 'vue-property-decorator';

  declare interface IboxOption {
    title: string;
    event: string;
  }

  declare interface IboxCustomButton {
    class: string;
    event: string;
  }

  @Component
  export default class InspiniaIbox extends Vue {
    @Prop({ default: '' }) title?: string;
    @Prop({ default: true }) showCloseButton?: boolean;
    @Prop({ default: true }) showCollapseButton?: boolean;
    @Prop({ default: true }) showOptionsButton?: boolean;
    @Prop({ default: () => [] }) options?: IboxOption[];
    @Prop({ default: () => [] }) customButtons?: IboxCustomButton[];
    @Prop({ default: false }) withFooter?: boolean;
    @Prop({ default: true }) withHeader?: boolean;
    @Prop({ default: false }) collapsed?: boolean;

    public isClosed: boolean = false;
    public isOptionsShown: boolean = false;
    public isCollapsed?: boolean = false;

    public close() {
      this.$emit('close');

      this.isClosed = true;
      this.isCollapsed = this.collapsed;
    }

    public toggleCollapsing() {
      this.isCollapsed = !this.isCollapsed;

      if (this.isCollapsed) {
        this.$emit('collapse');
      } else {
        this.$emit('expand');
      }
    }
  }
