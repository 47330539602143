
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import { VueEditor } from 'vue2-editor';
  import CodeEditor from '@/components/CodeEditor.vue';

  @Component({
    components: { VueEditor, CodeEditor },
    data() {
      return {
        isVueEditor: true
      };
    }
  })
  export default class ContentEditor extends Vue {
    @Prop() content: string;
    @Prop() hasError: boolean;

    public onValueChanged($event: string) {
      this.$emit('input', $event);
    }
  }
