
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class CodeEditor extends Vue {
  @Prop() content: string;

  mounted() {
    this.resetContent();
    this.insertContent();
  }

  updated() {
    this.insertContent();
  }

  get listeners() {
    return { ...this.$listeners, input: this.onInput };
  }

  public onInput(e: any) {
    this.$emit('input', e.target.innerText);
  }

  public insertContent() {
    const editableComponent = this.$refs.editable as HTMLElement;

    if (!editableComponent!.innerText && this.content) {
      editableComponent!.innerText = this.content;
    }
  }

  public resetContent() {
    const editableComponent = this.$refs.editable as HTMLElement;

    editableComponent!.innerText = '';
  }
}
